<template>
  <div>
    <div id="reportPreview" v-if="statisticalReportData" :class="'template_' + statisticalReportData.template">
      <div class="page" id="page1">
        <div class="title">{{ statisticalReportData.title }}</div>
        <div class="subtitle">{{ statisticalReportData.subtitle }}</div>
        <div class="timeRange" v-if="statisticalReportData.start_date && statisticalReportData.end_date">{{
            moment(statisticalReportData.start_date).format('D. M. YYYY') + ' - ' + moment(statisticalReportData.end_date).format('D. M. YYYY')
          }}
        </div>
      </div>


      <!--      SPD1KZ-->
      <div v-if="statisticalReportData.template === 0">
        <div class="page" id="page2">
          <div class="title">Uvod</div>

          <div class="summary" v-html="statisticalReportData.summary"></div>


          <div class="statsSummary" v-if="statistics && statistics.statsSummary" v-html="statistics.statsSummary"></div>
        </div>
        <div class="page" id="page3">
          <div class="chartOuterContainer chart1">
            <div class="chartTitle">Število vključenih uporabnikov</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForSteviloVkljucenihUporabnikov">
                <GChart
                  type="ColumnChart"
                  :data="statistics.dataForSteviloVkljucenihUporabnikov"
                  :options="chartOptionsNoLegend"
                />
              </div>
            </div>
          </div>
          <div class="chartOuterContainer chart2">
            <div class="chartTitle">Število uporabnikov po občini prebivališča</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForUporabnikiPoObciniStalnegaBivalisca">
                <GChart
                  type="PieChart"
                  :data="statistics.dataForUporabnikiPoObciniStalnegaBivalisca"
                  :options="pieChartOptions"
                />
              </div>
            </div>
          </div>

        </div>
        <div class="page" id="page4">

          <div class="chartOuterContainer chart1">
            <div class="chartTitle">Število opravljenih prevozov</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForSteviloOpravljenihPrevozov">
                <GChart
                  type="ColumnChart"
                  :data="statistics.dataForSteviloOpravljenihPrevozov"
                  :options="chartOptions"
                />
              </div>
            </div>
          </div>

          <div class="chartOuterContainer chart2">
            <div class="chartTitle">Število prevoženih kilometrov</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForSteviloPrevozenihKilometrov">
                <GChart
                  type="ColumnChart"
                  :data="statistics.dataForSteviloPrevozenihKilometrov"
                  :options="chartOptions"
                />
              </div>
            </div>
          </div>

        </div>

        <div class="page" id="page5">
          <div class="chartOuterContainer chart1">
            <div class="chartTitle">Število ur opravljenega prostovoljskega dela</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForSteviloProstovoljskihUr">
                <GChart
                  type="ColumnChart"
                  :data="statistics.dataForSteviloProstovoljskihUr"
                  :options="chartOptions"
                />
              </div>
            </div>
          </div>

          <div class="chartOuterContainer chart2">
            <div class="chartTitle">Mesto odhoda <small>(točka A)</small></div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForUporabnikiPoMestuOdhoda">
                <GChart
                  type="PieChart"
                  :data="statistics.dataForUporabnikiPoMestuOdhoda"
                  :options="pieChartOptions"
                />
              </div>
            </div>
          </div>

        </div>

        <div class="page" id="page6">
          <div class="chartOuterContainer chart1">
            <div class="chartTitle">Mesto prihoda <small>(točka B)</small></div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForUporabnikiPoMestuDestinacije">
                <GChart
                  type="PieChart"
                  :data="statistics.dataForUporabnikiPoMestuDestinacije"
                  :options="pieChartOptions"
                />
              </div>
            </div>
          </div>
          <div class="chartOuterContainer chart2">
            <div class="chartTitle" v-if="statistics && statistics.dataForDogodkiToyota">Število dogodkov v sodelovanju s
              Toyoto in OKS: <span style="color: #000; padding-left: 20px">{{ statistics.dataForDogodkiToyota }}</span>
            </div>
          </div>
          <div class="chartOuterContainer chart3">
            <div class="chartTitle">Napredek do 1000 prevozov</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForNapredekDo1k">
                <GChart
                  type="BarChart"
                  :data="statistics.dataForNapredekDo1k"
                  :options="stackedChartOptions"
                />
              </div>
            </div>
          </div>

        </div>
        <div class="page" id="page7">
          <div>
            <div class="title">Razlaga statističnih podatkov</div>
            <div class="summary" v-html="statisticalReportData.data_description"></div>
          </div>
        </div>

        <div class="page" id="page8">
          <div class="title">Dobra zgodba</div>

          <div class="imageAndContent">
            <img :src="$globalFunctions.getImage(statisticalReportData.photos[0].file)" class="contentImage"
                 v-if="statisticalReportData && statisticalReportData.photos && statisticalReportData.photos[0]"/>
            <div class="summary" v-html="statisticalReportData.story"></div>
          </div>
        </div>
      </div>

      <!--      Report for municipalities -->
      <div v-if="statisticalReportData.template === 1">
        <div class="page" id="page2">
          <div class="title">Uvod</div>
          <div class="summary text-justify"
               v-if="statistics && statistics.introText"
               v-html="statistics.introText"></div>

          <div class="statsSummary" v-if="statistics && statistics.statsSummary" v-html="statistics.statsSummary"></div>
        </div>
        <div class="page" id="page3">
          <div class="chartOuterContainer chart1 small">
            <div class="chartTitle">Število vključenih uporabnikov</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForSteviloVkljucenihUporabnikov">
                <GChart
                  type="ColumnChart"
                  :data="statistics.dataForSteviloVkljucenihUporabnikov"
                  :options="chartOptionsSmall"
                />
              </div>
            </div>
          </div>
          <div class="chartOuterContainer chart2 small">
            <div class="chartTitle">Število vseh uporabnikov</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForSteviloVsehUporabnikov">
                <GChart
                  type="ColumnChart"
                  :data="statistics.dataForSteviloVsehUporabnikov"
                  :options="chartOptionsSmall"
                />
              </div>
            </div>
          </div>
          <div class="chartOuterContainer chart3 small">
            <div class="chartTitle">Število uporabnikov po občini prebivališča</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForUporabnikiPoObciniStalnegaBivalisca">
                <GChart
                  type="PieChart"
                  :data="statistics.dataForUporabnikiPoObciniStalnegaBivalisca"
                  :options="pieChartOptionsSmall"
                />
              </div>
            </div>
          </div>

        </div>
        <div class="page" id="page4">
          <div class="chartOuterContainer chart1">
            <div class="chartTitle">Število opravljenih prevozov</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForSteviloOpravljenihPrevozov">
                <GChart
                  type="ColumnChart"
                  :data="statistics.dataForSteviloOpravljenihPrevozov"
                  :options="chartOptions"
                />
              </div>
            </div>
          </div>
          <div class="chartOuterContainer chart2">
            <div class="chartTitle">Število prevoženih kilometrov</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForSteviloPrevozenihKilometrov">
                <GChart
                  type="ColumnChart"
                  :data="statistics.dataForSteviloPrevozenihKilometrov"
                  :options="chartOptions"
                />
              </div>
            </div>
          </div>

        </div>

        <div class="page" id="page5">
          <div class="chartOuterContainer chart1">
            <div class="chartTitle">Število ur opravljenega prostovoljskega dela</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForSteviloProstovoljskihUr">
                <GChart
                  type="ColumnChart"
                  :data="statistics.dataForSteviloProstovoljskihUr"
                  :options="chartOptionsNoLegend"
                />
              </div>
            </div>
          </div>
          <div class="chartOuterContainer chart2">
            <div class="chartTitle">Delež prevozov glede na glavni namen poti</div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.reservationsData">
                <GChart
                  type="PieChart"
                  :data="statistics.dataForPrevoziPoNamenu"
                  :options="pieChartOptions"
                />
              </div>
            </div>
          </div>
        </div>

        <div class="page" id="page6">
          <div class="chartOuterContainer chart1">
            <div class="chartTitle">Mesto odhoda <small>(točka A)</small></div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForUporabnikiPoMestuOdhoda">
                <GChart
                  type="PieChart"
                  :data="statistics.dataForUporabnikiPoMestuOdhoda"
                  :options="pieChartOptions"
                />
              </div>
            </div>
          </div>

          <div class="chartOuterContainer chart2">
            <div class="chartTitle">Naselja oz. lokacije odhoda <small>(točka A)</small></div>
            <div v-if="statistics && statistics.reservationsData && statistics.reservationsData.by_months" class="departureNames">
            <span v-for="(item, index) in getDepartureAddresses">
              <span v-if="index < getDepartureAddresses.length-1">{{item}}, </span>
              <span v-if="index == getDepartureAddresses.length-1">{{item}}.</span>
            </span>
            </div>
          </div>

        </div>

        <div class="page" id="page7">
          <div class="chartOuterContainer chart1">
            <div class="chartTitle">Mesto prihoda <small>(točka B)</small></div>
            <div class="chartInnerContainer">
              <div v-if="statistics && statistics.dataForUporabnikiPoMestuDestinacije">
                <GChart
                  type="PieChart"
                  :data="statistics.dataForUporabnikiPoMestuDestinacije"
                  :options="pieChartOptions"
                />
              </div>
            </div>
          </div>

          <div class="chartOuterContainer chart2">
            <div class="chartTitle">Naselja oz. lokacije prihoda <small>(točka B)</small></div>
            <div v-if="statistics && statistics.reservationsData && statistics.reservationsData.by_months" class="departureNames">
            <span v-for="(item, index) in getDestinationAddresses">
              <span v-if="index < getDestinationAddresses.length-1">{{item}}, </span>
              <span v-if="index == getDestinationAddresses.length-1">{{item}}.</span>
            </span>
            </div>
          </div>
        </div>

        <div class="page" id="page8">
          <div>
            <div class="title">Koordinacija in IT novosti</div>
            <div class="summary text-justify" v-html="statisticalReportData.coordinationReport"></div>
          </div>
        </div>

      </div>


    </div>

  </div>
</template>

<script>
import Vue from "vue";
import axios from "@/axios";

export default {
  name: 'StatisticalReportForPdf',

  components: {},
  data() {
    return {
      statisticalReportData: {},
      statistics: {
        customersData: null,
        dataForSteviloVkljucenihUporabnikov: null,
        dataForSteviloVsehUporabnikov: null,
        dataForUporabnikiPoObciniStalnegaBivalisca: null,
        dataForUporabnikiPoMestuOdhoda: null,
        dataForUporabnikiPoMestuDestinacije: null,
        dataForSteviloOpravljenihPrevozov: null,
        dataForSteviloPrevozenihKilometrov: null,
        dataForSteviloProstovoljskihUr: null,
        dataForPrevoziPoNamenu: null,
        dailyReportData: null,
        reservationsData: null,
        volunteersData: null,
        statsSummary: null,
        introText: null,
        toyotaEventsCount: null,
        reservationsTotalCount: null,
        dataForNapredekDo1k: null,
        dataForDogodkiToyota: null
      },
      chartOptions: {
        title: '',
        subtitle: '',
        height: 380,
        'is3D': false,
        'legend': {position: "top", maxLines: 3},
        seriesType: 'bars',
      },
      chartOptionsNoLegend: {
        title: '',
        subtitle: '',
        height: 380,
        'is3D': false,
        'legend': 'none',
        seriesType: 'bars',
      },
      chartOptionsSmall: {
        title: '',
        subtitle: '',
        height: 200,
        'is3D': false,
        'legend': 'none',
        seriesType: 'bars',
      },
      stackedChartOptions: {
        title: '',
        subtitle: '',
        height: 300,
        'is3D': false,
        'legend': {position: "top", maxLines: 3},
        isStacked: 'percent',
        seriesType: 'bars',
      },
      pieChartOptions: {
        title: '',
        subtitle: '',
        height: 380,
        'is3D': true,
        'legend': {position: "right"},
        sliceVisibilityThreshold: 0
      },
      pieChartOptionsSmall: {
        title: '',
        subtitle: '',
        height: 300,
        'is3D': true,
        'legend': {position: "right"},
        sliceVisibilityThreshold: 0
      },
      activeCustomersData: null,
      unitData: null,
      cars: null
    }
  },

  watch: {
    statisticalReportData: {
      handler: function (someData) {
        // this.getStatistics();
      },
      immediate: true
    }
  },


  computed: {
    getDepartureAddresses() {
      const _this = this;
      let departureAddresses = [];

      _.each(_this.statistics.reservationsData.by_months, function (val,key) {
        _.each(val, function(_val,_key){
          if(departureAddresses.indexOf(_val.departure.address_name) < 0) {
            departureAddresses.push(_val.departure.address_name);
          }
        });
      });

      departureAddresses = departureAddresses.sort();

      return departureAddresses;

    },

    getDestinationAddresses() {
      const _this = this;
      let destinationAddresses = [];

      _.each(_this.statistics.reservationsData.by_months, function (val,key) {
        _.each(val, function(_val,_key){
          if(destinationAddresses.indexOf(_val.destination.address_name) < 0) {
            destinationAddresses.push(_val.destination.address_name);
          }
        });
      });

      destinationAddresses = destinationAddresses.sort();

      return destinationAddresses;

    }
  },

  mounted() {
    const _this = this;

    _this.$vs.loading();

    console.log(Vue.prototype.$http.defaults.headers);
    console.log(" this.$route.query", this.$route.query);

    if (_this.$route.params.statisticalReportId) {
      if (this.$route.query.jwt) {

        Vue.prototype.$http.interceptors.request.use(
          config => {
            const token = this.$route.query.jwt;
            if (token) {
              if (config.url.includes('https://api.sopotniki.org/')) {
                config.headers.common["Authorization"] = 'Bearer ' + token;
              } else {
                config.headers.common["Authorization"] = token;
              }
            }
            return config;
          },
          error => {
            return Promise.reject(error);
          }
        );
      }
      _this.getReportData();
    } else {
      _this.$vs.loading.close();
    }
  },


  methods: {
    async getUnit() {
      const _this = this;

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionNew + 'units/' + _this.unitId)
        .then((res) => {

          _this.unitData = res.data.data;
          _this.cars = res.data.data.cars;

        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });

    },

    async getReportData() {
      const _this = this;

      Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'statistical-report/' + _this.$route.params.statisticalReportId)
        .then((res) => {

          _this.statisticalReportData = res.data.data;
          _this.statisticalReportData.unit_id = res.data.data.unit.id;
          _this.statisticalReportData.unitId = res.data.data.unit.id;
          _this.statisticalReportData.template = parseInt(res.data.data.template);
          _this.unitId = res.data.data.unit.id;

          _this.$vs.loading.close();

          _this.getUnit().then(() => {
            _this.getStatistics().then(() => {
              _this.getCustomers().then(() => {
                _this.statistics.statsSummary = _this.formatDataForStatsSummary();
                _this.statistics.introText = _this.formatDataForIntroText();
                _this.$forceUpdate();
                _this.$vs.loading.close();
              });
            });
          });


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    async getStatistics() {
      const _this = this;

      _this.$vs.loading();


      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/daily_report/' + _this.statisticalReportData.unit_id,
        {
          params: {
            start: _this.moment(_this.statisticalReportData.start_date).valueOf(),
            end: _this.moment(_this.statisticalReportData.end_date).valueOf()
          }
        }
      )
        .then((res) => {
          _this.statistics.dailyReportData = res.data.data;
          _this.statistics.dataForSteviloPrevozenihKilometrov = _this.formatDataForSteviloPrevozenihKilometrov(res.data.data);
          _this.statistics.dataForSteviloProstovoljskihUr = _this.formatDataForSteviloProstovoljskihUr(res.data.data);
        });

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/customers/' + _this.statisticalReportData.unit_id,
        {
          params: {
            start: _this.moment(_this.statisticalReportData.start_date).valueOf(),
            end: _this.moment(_this.statisticalReportData.end_date).valueOf()
          }
        }
      )
        .then((res) => {
          _this.statistics.customersData = res.data.data;
          _this.statistics.dataForUporabnikiPoObciniStalnegaBivalisca = _this.formatDataForUporabnikiPoObciniStalnegaBivalisca(res.data.data);
        });

      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/reservations/' + _this.statisticalReportData.unit_id,
        {
          params: {
            start: _this.moment(_this.statisticalReportData.start_date).valueOf(),
            end: _this.moment(_this.statisticalReportData.end_date).valueOf()
          }
        }
      )
        .then(async (res) => {
          _this.statistics.reservationsData = res.data.data;
          _this.statistics.dataForSteviloVkljucenihUporabnikov = _this.formatDataForSteviloVkljucenihUporabnikov(res.data.data);
          _this.statistics.dataForSteviloOpravljenihPrevozov = _this.formatDataForSteviloOpravljenihPrevozov(res.data.data);
          _this.statistics.dataForUporabnikiPoMestuOdhoda = _this.formatDataForUporabnikiPoObciniStalnegaBivalisca(res.data.data.departure);
          _this.statistics.dataForUporabnikiPoMestuDestinacije = _this.formatDataForUporabnikiPoObciniStalnegaBivalisca(res.data.data.destination);
          _this.statistics.dataForPrevoziPoNamenu = _this.formatDataForPrevoziPoNamenu(res.data.data);
          _this.statistics.dataForSteviloVsehUporabnikov = await _this.formatDataForSteviloVsehUporabnikov(res.data.data);
        });


      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'statistics/volunteersOverview/' + _this.unitId,
        {
          params: {
            start: _this.moment(_this.statisticalReportData.start_date).valueOf(),
            end: _this.moment(_this.statisticalReportData.end_date).valueOf()
          }
        }
      )
        .then((res) => {
          console.log("volunteersOverview", res);
          _this.statistics.volunteersData = res.data;
          _this.$forceUpdate();
        });

      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'reservations/count/',
        {
          params: {
            unit: _this.statisticalReportData.unit_id,
            end: _this.moment(_this.statisticalReportData.end_date).valueOf(),
          }
        }
      )
        .then((res) => {
          _this.reservationsTotalCount = res.data.data;
          _this.statistics.dataForNapredekDo1k = _this.formatDataForNapredekDo1k(res.data.data);
          _this.$forceUpdate();
        });


      await Vue.prototype.$http.get(Vue.prototype.$config.api.sopotnikiVPS + 'reservations/count/',
        {
          params: {
            unit: _this.statisticalReportData.unit_id,
            end: _this.moment(_this.statisticalReportData.end_date).valueOf(),
            toyotaEvent: true
          }
        }
      )
        .then((res) => {
          _this.statistics.dataForDogodkiToyota = res.data.data;
          _this.$forceUpdate();
        });

    },

    formatDataForStatsSummary() {
      const _this = this;

      let numberOfTransportations = 0;


      _.each(_this.statistics.reservationsData.by_months, function (val, key) {
        numberOfTransportations += val.length;
      });
      console.log("numberOfTransportations", numberOfTransportations);
      let averageDisatancePerTransportation = parseInt(_this.statistics.reservationsData.route_distance_total / numberOfTransportations).toFixed();

      const totalNumberOfUsersAtTheEndOfReport = _this.statistics.dataForSteviloVsehUporabnikov[_this.statistics.dataForSteviloVsehUporabnikov.length-1][2];

      let statsSummary = 'V obdobju od ' + _this.moment(_this.statisticalReportData.start_date).format('D. M. YYYY') + ' do ' + _this.moment(_this.statisticalReportData.end_date).format('D. M. YYYY') +
        ' smo opravili ' + numberOfTransportations + ' prevozov in prevozili ' + _this.statistics.dailyReportData.odometer_total + ' kilometrov. Vozniki prostovoljci so opravili ' + _this.statistics.dailyReportData.volunteer_hours_total + ' ur prostovoljskega dela. <br/>' +
        'Povprečna razdalja prevoza je bila ' + averageDisatancePerTransportation + ' kilometrov. <br/>' +
        'V bazo uporabnikov smo do ' + _this.moment(_this.statisticalReportData.end_date).format('D. M. YYYY') + ' vpisali ' + totalNumberOfUsersAtTheEndOfReport + ' aktivnih uporabnikov storitve.';

      return statsSummary;
    },

    formatDataForIntroText() {
      const _this = this;

      let activeVolunteers = [];
      _.each(_this.statistics.volunteersData.by_months, function (val, key) {
        _.each(val, function (_val, _key) {
          if (activeVolunteers.indexOf(_val.volunteers_id) < 0) {
            activeVolunteers.push(_val.volunteers_id);
          }
        });
      });

      const totalNumberOfUsersAtTheEndOfReport = _this.statistics.dataForSteviloVsehUporabnikov[_this.statistics.dataForSteviloVsehUporabnikov.length-1][2];

      let statsIntroText = 'V obdobju od ' + _this.moment(_this.statisticalReportData.start_date).format('D. M. YYYY') + ' do ' + _this.moment(_this.statisticalReportData.end_date).format('D. M. YYYY') + ' je v enoti ' + _this.statisticalReportData.unit.name + ' - ' + _this.statisticalReportData.unit.coveredMunicipalities + ' na terenu aktivno sodelovalo ' + activeVolunteers.length + ' prostovoljcev. V bazo uporabnikov smo sprejeli ' + totalNumberOfUsersAtTheEndOfReport + ' starejših občanov.<br/><br/>';

      _.each(_this.statistics.volunteersData.by_months, function (val, key) {
        statsIntroText += _this.formatDataForIntroTextPerMonth(key);
      });

      return statsIntroText;
    },

    formatDataForIntroTextPerMonth(month) {
      const _this = this;
      console.log("month", month);

      let monthName = null;

      switch (parseInt(month)) {
        case 0:
          monthName = "januarju";
          break;
        case 1:
          monthName = "februarju";
          break;
        case 2:
          monthName = "marcu";
          break;
        case 3:
          monthName = "aprilu";
          break;
        case 4:
          monthName = "maju";
          break;
        case 5:
          monthName = "juniju";
          break;
        case 6:
          monthName = "juliju";
          break;
        case 7:
          monthName = "avgustu";
          break;
        case 8:
          monthName = "septembru";
          break;
        case 9:
          monthName = "oktobru";
          break;
        case 10:
          monthName = "novembru";
          break;
        case 11:
          monthName = "decembru";
          break;
      }

      let steviloPrevozov = _this.statistics.reservationsData.by_months[parseInt(month)].length;
      let steviloUporabnikov = [];

      _.each(_this.statistics.reservationsData.by_months[parseInt(month)], function (val, key) {
        if (steviloUporabnikov.indexOf(val.customer_id) < 0) {
          steviloUporabnikov.push(val.customer_id);
        }
      });


      let odometerTotal = 0;
      let volunteerHoursTotal = 0;
      let volunteerMinutesTotal = 0;
      _.each(_this.statistics.dailyReportData.by_months[parseInt(month)], function (val, key) {
        odometerTotal += parseInt(val.odometer_total ? val.odometer_total : 0);
        volunteerHoursTotal += (val.volunteer_hours_total ? parseInt(val.volunteer_hours_total.split(':')[0]) : 0);
        volunteerMinutesTotal += (val.volunteer_hours_total ? parseInt(val.volunteer_hours_total.split(':')[1]) : 0);
      });
      let additionalHours = Math.floor(volunteerMinutesTotal / 60);
      let minutes = volunteerMinutesTotal % 60;
      volunteerHoursTotal += additionalHours + (minutes >= 30 ? 1 : 0);


      let numberOfActiveCustomersPerMonth = 0;
      _.each(_this.statistics.dataForSteviloVsehUporabnikov, function (val, key) {
        if(val[0].includes(_this.moment().month(month).format('MMMM'))){
          numberOfActiveCustomersPerMonth = val[2];
        }
      });

      let statsIntroTextPerMonth = 'V ' + monthName + ' smo opravili ' + steviloPrevozov + ' prevozov (en prevoz pomeni povratno vožnjo) in prepeljali ' + steviloUporabnikov.length + ' različnih uporabnikov' + (steviloPrevozov > steviloUporabnikov.length ? ', kar pomeni, da so nekateri storitev koristili večkrat.' : '.') + ' Prostovoljci so bili na terenu ' + volunteerHoursTotal + ' ur in v tem času prevozili ' + odometerTotal + ' kilometrov. V bazo uporabnikov smo sprejeli ' + numberOfActiveCustomersPerMonth + ' starejših občanov.<br/><br/>';

      return statsIntroTextPerMonth;

    },

    async getCustomers() {
      const _this = this;

      _this.$vs.loading();


      await Vue.prototype.$http.get(Vue.prototype.$config.api.productionOld + 'customers/unit/' + _this.statisticalReportData.unit_id)
        .then((res) => {


          _this.activeCustomersData = _.filter(res.data.data, {active: 1});
          console.log("TOLE: ", res.data.data);

          _this.$vs.loading.close();


        })
        .catch((error) => {
          console.log("error", error);
          _this.$vs.loading.close();
          return false
        });
    },

    formatDataForSteviloVkljucenihUporabnikov(reservationsData) {
      const _this = this;

      console.log("reservationsData", reservationsData);

      let _data = [];
      _data.push(['Mesec', 'Število vključenih uporabnikov', {role: 'annotation'}]);

      _.each(reservationsData.by_months, function (val, idx) {
        let month = _this.moment(val[0].departure_time).format("MMMM, YYYY");
        let _val = _.uniqBy(val, function (e) {
          return e.customer_id;
        });
        _data.push([month, _val.length, _val.length]);
      });

      return _data
    },

    async formatDataForSteviloVsehUporabnikov(customersData) {
      const _this = this;

      let _data = [];
      _data.push(['Mesec', 'Skupno število uporabnikov', { role: 'annotation' }]);

      let months = customersData.by_months;
      let year = _this.moment(_this.statisticalReportData.start_date).format('YYYY');

      _this.getCustomers().then(() => {
        for (const month of Object.keys(months)) {
          let customersPerMonth = [];
          _.each(_this.activeCustomersData, function (val, key) {
            if (_this.moment(val.created_at) <= _this.moment().year(year.toString()).month(month)) {
              customersPerMonth.push(val);
            }
          });
          _data.push([_this.moment().month(month).format('MMMM') + ', ' + year, customersPerMonth.length, customersPerMonth.length]);
        }
      });
      return _data;
    },

    formatDataForUporabnikiPoObciniStalnegaBivalisca(customersData) {
      let _data = [];
      _data.push(['Občina', 'Število uporabnikov', {role: 'annotation'}]);

      _.each(customersData, function (val) {
        _data.push([val.name + ' (' + val.count + ')', val.count, val.count]);
      });

      return _data
    },

    formatDataForPrevoziPoNamenu(reservationsData) {
      const _this = this;

      let _data = [];
      _data.push(['Namen', 'Število prevozov']);
      let results = {};


      if (reservationsData && Object.keys(reservationsData.by_months).length) {
        _.each(reservationsData.by_months, function (val, idx) {

          _.each(val, function (_val, _key) {
            if (results[_this.$globalFunctions.getReservationPurposeLabel(_val.reservation_purpose)]) {
              results[_this.$globalFunctions.getReservationPurposeLabel(_val.reservation_purpose)]++;
            } else {
              results[_this.$globalFunctions.getReservationPurposeLabel(_val.reservation_purpose)] = 1;
            }
          });
        });
      } else {
        _data.push(['', 0]);
      }
      _.each(results, function (val, key) {
        _data.push([key + ' (' + val + ')', val]);
      })

      return _data
    },

    formatDataForSteviloOpravljenihPrevozov(reservationsData) {
      const _this = this;

      // get cars for unit
      //let cars = _this.cars;
      let cars = _this.cars.filter((car) => { return !car.name.includes('ni več aktivno') });

      let _data = [];

      if(cars.length === 1) {
        _this.chartOptions.legend = 'none';

        _data.push(['Mesec', 'Število prevozov', {role: 'annotation'}]);

        if (reservationsData && Object.keys(reservationsData.by_months).length) {
          _.each(reservationsData.by_months, function (val, idx) {
            let month = _this.moment(val[0].departure_time).format("MMMM, YYYY");
            _data.push([month, val.length, val.length]);
          });
        } else {
          _data.push(['', 0, 0]);
        }
      } else {

        _data.push(['Mesec']);
        _.each(cars, function (val, idx) {
          if(val.name !== null) {
            _data[0].push(val.name);
            _data[0].push({role: 'annotation'});
          }
        });
        _data[0].push('Skupaj');
        _data[0].push({role: 'annotation'});

        if (reservationsData && Object.keys(reservationsData.by_months).length) {
          _.each(reservationsData.by_months, function (val, idx) {
            let month = _this.moment(val[0].departure_time).format("MMMM, YYYY");
            let numberOfTransportationsByCarsByMonth = [];
            let totalNumberOfTransportationsByMonth = val.length;
            _.each(cars, function (_val, _idx) {
              let count = _.filter(val, {car_id: _val.id}).length;
              numberOfTransportationsByCarsByMonth.push(count, count);
            });
            _data.push(_.concat([month], numberOfTransportationsByCarsByMonth, [totalNumberOfTransportationsByMonth], [totalNumberOfTransportationsByMonth]));
          })
        }
      }

      return _data
    },

    formatDataForSteviloPrevozenihKilometrov(dailyReportsData) {
      const _this = this;

      console.log("dailyReportsData", dailyReportsData);

      // get cars for unit
      //let cars = _this.cars;
      let cars = _this.cars.filter((car) => { return !car.name.includes('ni več aktivno') });

      let _data = [];

      // single car unit
      if(cars.length === 1) {

        _this.chartOptions.legend = 'none';

        _data.push(['Mesec', 'Število prevoženih kilometrov', {role: 'annotation'}]);

        _.each(dailyReportsData.by_months, function (val, idx) {
          let month = _this.moment(val[0].date).format("MMMM, YYYY");
          let odometerTotal = 0;

          _.each(val, function (_val, _idx) {
            odometerTotal += parseInt(_val.odometer_total ? _val.odometer_total : 0);
          });

          _data.push([month, odometerTotal, odometerTotal]);
        });

        // multiple cars unit
      } else {

        _data.push(['Mesec']);

        _.each(cars, function (val) {
          _data[0].push(val.name);
          _data[0].push({role: 'annotation'});
        });
        _data[0].push('Skupaj');
        _data[0].push({role: 'annotation'});

        let byCarsAndMonths = [];
        _.each(dailyReportsData.odometer_by_car_by_month, function (car, idx) {

          _.each(car.odometer_by_month, function (_val, _key){
            let date = _val.month.split('-')[0] + '-' + (parseInt(_val.month.split('-')[1]) + 1);
            let month = _this.moment(date, 'YYYY-M').format("MMMM, YYYY");

            if(car.car_id !== 'null') {
              byCarsAndMonths.push({
                'car': car.car_id,
                'month': month,
                'odometerTotal': parseInt(_val.odometer_total ? _val.odometer_total : 0)
              });
            }

          });
        });

        let startMonth = _this.moment(_this.statisticalReportData.start_date).month();
        let endMonth = _this.moment(_this.statisticalReportData.end_date).month();
        let year = _this.moment(_this.statisticalReportData.start_date).year();

        for (let i = startMonth; i <= endMonth; i++) {
          let _month = _this.moment().month(i).year(year).format('MMMM, YYYY');

          let kilometersByCars = [];
          _.each(cars, function (_val, _idx) {
            let distance = _.filter(byCarsAndMonths, {car: _val.id.toString()});
            distance = _.filter(distance, {month: _month}).length > 0 ?  _.filter(distance, {month: _month})[0].odometerTotal : 0;

            kilometersByCars.push(distance, distance);
          });
          let sum = _.sum(kilometersByCars)/2;
          _data.push(_.concat([_month], kilometersByCars, sum, sum));
        }
      }

      console.log("_data - število prevoženih kilometrov", _data);

      return _data
    },

    formatDataForSteviloProstovoljskihUr(dailyReportsData) {
      const _this = this;

      console.log("dailyReportsData", dailyReportsData);
      let _data = [];
      _data.push(['Mesec', 'Število ur', {role: 'annotation'}]);

      _.each(dailyReportsData.by_months, function (val, idx) {
        let month = _this.moment(val[0].date).format("MMMM, YYYY");
        let volunteerHours = 0;
        let volunteerMinutes = 0;

        _.each(val, function (_val, _idx) {
          volunteerHours = (_val.volunteer_hours_total ? parseInt(_val.volunteer_hours_total.split(':')[0]) : 0) + volunteerHours;
          volunteerMinutes = (_val.volunteer_hours_total ? parseInt(_val.volunteer_hours_total.split(':')[1]) : 0) + volunteerMinutes;
        });

        let additionalHours = Math.floor(volunteerMinutes / 60);
        let minutes = volunteerMinutes % 60;

        _data.push([month, _this.timeToDecimal(volunteerHours + additionalHours + ':' + minutes), _this.timeToDecimal(volunteerHours + additionalHours + ':' + minutes)]);
      });

      console.log("_data - število prostovoljskih ur", _data);

      return _data
    },

    timeToDecimal(t) {
      let arr = t.split(':');
      let dec = parseInt((arr[1] / 6) * 10, 10);

      return parseFloat(parseInt(arr[0], 10) + '.' + (dec < 10 ? '0' : '') + dec);
    },

    formatDataForNapredekDo1k(count) {
      let _data = [
        ['Prevozi', 'že opravljeni prevozi', 'še ne opravljeni prevozov'],
        ['prevozi', count, (1000 - count <= 0) ? 0 : (1000 - count)],
      ];

      return _data;
    },
  }
}
</script>
